import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";
import amanrowLogo from "./Images/amanrow-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsEnvelope } from "react-icons/bs";
import { FaBalanceScale } from "react-icons/fa";
import { HiOutlineBellAlert } from "react-icons/hi2";

import { TbQuestionMark } from "react-icons/tb";
import { faBars, faCoffee } from "@fortawesome/free-solid-svg-icons";
import blankprofile from "./Images/blankprofile.png";
import { Link } from "react-router-dom";

import { useQuery } from "./utils/sharedfunction";

import ChartOfAccounts from "./component/Acc/ChartOfAccounts";
import DailyEntry from "./component/Acc/DailyEntry";
import Invoice from "./component/Acc/Invoice/Invoice";

import CertificationAccreditation from "./component/Aca/CertificationAccreditation";
import Decisions from "./component/Aca/Decisions";
import Universities from "./component/Aca/Universities";
import AcaUsersAndPrivileges from "./component/Aca/AcaUsersAndPrivileges";

import EmployeeDetails from "./component/Hrs/EmployeeDetails";
import HrsUsersAndPrivileges from "./component/Hrs/HrsUsersAndPrivileges";
import ItemControl from "./component/Inv/item_control/ItemControl";
import CaseDetails from "./component/Lcc/CaseDetails";
import MyRole from "./component/Lcc/MyRole";
import HearingsManagment from "./component/Lcc/HearingsManagment";
import AttorneyCases from "./component/Lcc/AttorneyCases";
import CasesAttornies from "./component/Lcc/CasesAttornies";
import PowerOfAttorny from "./component/Lcc/PowerOfAttorny";
import LccUsersAndPrivileges from "./component/Lcc/LccUsersAndPrivileges";

import LccClients from "./component/Lcc/LccClients";
import ClientsPage from "./component/Crm/ClientsPage";
import OrganizationStructure from "./component/Org/OrganizationStructure";
import PurchaseOrder from "./component/Pur/PurchaseOrder";

import CallCenter from "./component/Wkf/Request/CallCenter";
import Request from "./component/Wkf/Request/Request";
import Attachment from "./component/Wkf/Request/Attachment";

import Agenda from "./component/Agenda/Agenda";

import SideBar from "./component/sidebar/SideBar";

import { useDispatch, useSelector } from "react-redux";

import HlpHelpContent from "./component/sidebar/HlpHelpContent";
import ThemeLanguageSelector from "./component/ThemeSelecterLanguage/ThemeLanguageSelector";
import { tugle_section, build_the_menu, fix_menu } from "./Aman_js";
import System_menu from "./System_menu";

import $ from "jquery";
let parent = [];
const Aman = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  const history = useHistory();
  const [menus, setMenu] = useState([]);
  const [parent, setParent] = useState([]);
  const [userData, setUserData] = useState({});
  const [companySystems, setCompanySystems] = useState([]);
  const [userPages, setUserPages] = useState([]);
  const [groupPages, setGroupPages] = useState([]);
  const [systemAdmintrator, setSystemAdmintrator] = useState([]);
  const [notActiveUsers, setNotActiveUsers] = useState([]);
  const [userControl, setUserControl] = useState("");
  const [functionCode, setFunctionCode] = useState("");
  const [helpContent, setHelpContent] = useState(false);
  const parentRef = useRef(null);

  var first_time = 1;

  useEffect(() => {
    get_system_menu();
  }, []);

  var branch_code = 0;
  useEffect(() => {
    let functionName = query.get("fn");
    let pgid = query.get("pgid");
    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    if (userDataLocal)
      if (functionName) {
        setUserControl(functionName);
      }
  }, []);

  useEffect(() => {
    let pgid = query.get("pgid");

    if (pgid)
      if (parentRef.current) {
        if (document.getElementById("page_name_area")) {
          console.log(document.getElementById(pgid).previousSibling.innerText);
          document.getElementById("page_name_area").innerText =
            document.getElementById(pgid).previousSibling.innerText;
        }
        if (
          document
            .getElementById(pgid)
            .parentNode.parentNode.classList.contains("hedden")
        ) {
          document
            .getElementById(pgid)
            .parentNode.parentNode.classList.remove("hedden");
        } else {
          document.getElementById(pgid).classList.add("hedden");
        }
      }
  }, [parentRef.current]);

  const togle = () => {
    console.log("tugle ", first_time);
    var element = document.getElementById("menusidebar");
    element.classList.remove("deactive");
    $("html").toggleClass("minify");
    var element = document.getElementById("mySidenav");
    element.classList.remove("mfyactive");
  };

  const togle_Section = (
    section_id,
    function_name,
    authorised,
    page_name,
    system_cd,
    page_cd,
  ) => {
    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    if (system_cd !== "Lcs") {
      if (function_name > "") {
        history.push(`/main?pgid=${section_id}&fn=${function_name}`);
        setUserControl(function_name);

        document.getElementById("page_name_area").innerText = page_name;
      } else {
        if (document.getElementById(section_id).classList.contains("hedden")) {
          document.getElementById(section_id).classList.remove("hedden");
        } else {
          document.getElementById(section_id).classList.add("hedden");
        }
      }
    } else {
      if (document.getElementById(section_id).children.length === 0) {
        window.location.assign(
          `http://localhost:4701/cpanel/lc.aspx?pgid=${page_cd}&fn=${function_name}&usr=${userDataLocal.user_cd}&lang=en&tkn=${userDataLocal.token}`,
        );
      } else {
        if (document.getElementById(section_id).classList.contains("hedden")) {
          document.getElementById(section_id).classList.remove("hedden");
        } else {
          document.getElementById(section_id).classList.add("hedden");
        }
      }
    }
  };

  function openNav(sidebarid) {
    //document.getElementById(sidebarid).style.width = "20%";
    var element = document.getElementById("menusidebar");
    element.classList.add("deactive");
    $("html").addClass("minify");

    var element = document.getElementById(sidebarid);
    element.classList.add("mfyactive");
  }

  function closeSideNav(sidebarid) {
    var element = document.getElementById(sidebarid);
    element.classList.remove("mfyactive");
    document.getElementById(sidebarid).style.width = "0";
  }

  const get_system_menu = async () => {
    let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
    let input = {};
    let header = {};

    let token = query.get("tk");
    let lang = query.get("lang");
    let user_cd = query.get("usr");
    let company = query.get("cmp");

    if (userDataLocal) {
      input = {
        input: {
          actioncode: "MENU",
        },
        info: {
          lang: userDataLocal.lang,
          user_cd: userDataLocal.user_cd,
        },
      };
      header = {
        Authorization: `Bearer ${userDataLocal.token}`,
      };

      lang = userDataLocal.lang;
      user_cd = userDataLocal.user_cd;
      company = userDataLocal.company_cd;
      token = userDataLocal.token;
    }

    if (token) {
      input = {
        input: {
          actioncode: "MENU",
        },
        info: {
          lang: lang,
          user_cd: user_cd,
          company: company,
        },
      };
      header = {
        Authorization: `Bearer ${token}`,
      };
    }
    console.log("menu and main input", input);
    if (userDataLocal || token)
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_BACK_END_URL}/api/iip/main_page_menu`,
          input,
          {
            headers: header,
          },
        );

        let data = {
          // here we assign names for global variable
          user_name: res.data.output.user.user_name,
          branch_cd: res.data.output.user.branch_cd,
          company_branch_cd: res.data.output.user.company_branch_cd,
          currancy_cd: res.data.output.user.currancy_cd,
          currancy_name: res.data.output.user.currancy_name,
          date_format: res.data.output.user.date_format,
          super_user: res.data.output.user.super_user,
          user_cd: res.data.output.user.user_cd,
          user_language_cd: res.data.output.user.user_language_cd,
          user_type_cd: res.data.output.user.user_type_cd,
          company_cd: company,
          login_name: "",
          email: "",
          token: token,
        };

        dispatch({ type: "USERINFO", payload: data }); //to store in redux

        if (typeof Storage !== undefined) {
          localStorage.setItem(
            "amanrowuserData",
            JSON.stringify({
              user_cd: res.data.output.user.user_cd,
              branch_cd: res.data.output.user.branch_cd,
              company_cd: company,
              lang: lang,
              login_name: "",
              email: "",
              token: token,
            }),
          );
        }

        console.log("menu and output", res);

        if (res.data.output.user) {
          setUserData(res.data.output.user);
        }
        if (res.data.output.company_systems) {
          setCompanySystems(res.data.output.company_systems);
        }
        if (res.data.output.user_pages) {
          setUserPages(res.data.output.user_pages);
        }
        if (res.data.output.user_group_pages) {
          setGroupPages(res.data.output.user_group_pages);
        }
        if (res.data.output.system_admintrator) {
          setSystemAdmintrator(res.data.output.system_admintrator);
        }
        if (
          res.data.output.not_active_users &&
          res.data.output.not_active_users.length > 0
        ) {
          setNotActiveUsers(res.data.output.not_active_users);
        }

        setMenu(res.data.output.menu);

        parent.push(res.data.menu[0].parent_id);
        setParent([res.data.menu[0].parent_id]);
      } catch (err) {
        console.log("error", err.response);

        if (err.response.status === 401) {
          alert("Token Invalid or UnAthorized");
        }

        if (err.response.status === 403) {
          alert("Forbidden or Header Authorization not found");
        }

        if (err.response.status === 401 || err.response.status === 403) {
          history.push("/");
        }
      }
  };

  return (
    <>
      <div className={`main-container direction-change`}>
        <div className="page-header">
          <div className="ph-left">
            <div className="menu-toggle" onClick={togle}>
              {" "}
              <FontAwesomeIcon icon={faBars} />
            </div>
            <div
              style={{ color: "white", margin: "0px 16px", fontSize: "18px" }}
            >
              {" "}
              {userData && userData.company_name}{" "}
            </div>
          </div>
          <div className="ph-right">
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                margin: "0px 10px",
              }}
            >
              <div style={{ margin: "0px 10px" }}>
                <TbQuestionMark
                  color="white"
                  fontSize="24px"
                  onClick={() => {
                    setHelpContent(true);
                  }}
                />
              </div>
              <div style={{ margin: "0px 10px" }}>
                <BsEnvelope color="white" fontSize="20px" />
              </div>
              <div style={{ margin: "0px 10px" }}>
                <HiOutlineBellAlert color="white" fontSize="22px" />
              </div>
              <div
                style={{ margin: "0px 10px" }}
                onClick={() => openNav("mySidenav")}
              >
                {userData && userData.user_cd && (
                  <>
                    {userData.profile_image_name ? (
                      <img
                        src={`https://files.amanrow.com/user/${userData.profile_image_name}`}
                        className="profile_image"
                        style={{ border: "solid 2px whitesmoke" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={blankprofile}
                        className="profile_image"
                        alt=""
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <SideBar closeSideNav={closeSideNav} />

        {helpContent && (
          <HlpHelpContent
            setHelpContent={setHelpContent}
            function_cd={functionCode}
          />
        )}
        <nav className="sidebar" id="menusidebar">
          <div id="menu_area">
            <div style={{ minHeight: "46px", textAlign: "center" }}>
              {userData && userData.logo_name && (
                <img
                  src={userData.logo_name}
                  style={{ height: "60px" }}
                  alt=""
                />
              )}

              {/*company logo will be here*/}
            </div>

            {menus && (
              <System_menu
                companySystems={companySystems}
                userPages={userPages}
                groupPages={groupPages}
                systemAdmintrator={systemAdmintrator}
                superUser={userData.super_user}
                menus={menus}
                setUserControl={setUserControl}
                setFunctionCode={setFunctionCode}
              />
            )}
            <div
              style={{ padding: "10px" }}
              onClick={() => setUserControl("Agenda")}
            >
              {" "}
              Agenda
            </div>
          </div>
        </nav>
        <div className="page-container" style={{ height: "100vh" }}>
          <div className="page-bar" style={{ height: "46px" }}>
            <div id="page_name_area" className="page-bar-left">
              {userData && userData.branch_name}
            </div>
            <div
              id="function_code"
              className="page-bar-left"
              style={{ padding: "0px 6px" }}
            ></div>
            <div id="branch_name_area" className="page-bar-right">
              {" "}
              {userData && userData.branch_name}
            </div>
          </div>
          <div id="page_area">
            {userControl === "EmployeeDetails" && <EmployeeDetails />}
            {userControl === "HrsUsersAndPrivileges" && (
              <HrsUsersAndPrivileges
                branch_code={userData.branch_cd}
                notActiveUsers={notActiveUsers}
                setNotActiveUsers={setNotActiveUsers}
              />
            )}

            {userControl === "ItemControl" && <ItemControl />}
            {userControl === "PurchaseOrder" && <PurchaseOrder />}

            {userControl === "ChartOfAccounts" && <ChartOfAccounts />}
            {userControl === "DailyEntry" && <DailyEntry />}
            {userControl === "invoice" && <Invoice />}

            {userControl === "Request" && <Request />}
            {userControl === "CallCenter" && <CallCenter />}

            {userControl === "CaseDetails" && <CaseDetails />}
            {userControl === "MyRole" && <MyRole />}
            {userControl === "AttorneyCases" && <AttorneyCases />}
            {userControl === "HearingsManagment" && <HearingsManagment />}
            {userControl === "CasesAttornies" && <CasesAttornies />}
            {userControl === "ClientsPage" && <ClientsPage />}
            {userControl === "LccClients" && <LccClients />}
            {userControl === "PowerOfAttorny" && <PowerOfAttorny />}
            {userControl === "LccUsersAndPrivileges" && (
              <LccUsersAndPrivileges
                branch_code={userData.branch_cd}
                notActiveUsers={notActiveUsers}
                setNotActiveUsers={setNotActiveUsers}
              />
            )}

            {userControl === "CertificationAccreditation" && (
              <CertificationAccreditation />
            )}
            {userControl === "Decisions" && <Decisions />}
            {userControl === "Universities" && <Universities />}
            {userControl === "AcaUsersAndPrivileges" && (
              <AcaUsersAndPrivileges
                notActiveUsers={notActiveUsers}
                setNotActiveUsers={setNotActiveUsers}
              />
            )}

            {userControl === "OrganizationStructure" && (
              <OrganizationStructure />
            )}
            {userControl === "Agenda" && <Agenda />}
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal right fade Notification-modal"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {" "}
                Title fdsfd
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div>
                {" "}
                <Attachment itemData={{ data1: "good morning" }} />
              </div>
            </div>
            {/*<div className='modal-footer'>*/}
            {/*  <button*/}
            {/*    type='button'*/}
            {/*    className='btn btn-secondary'*/}
            {/*    data-bs-dismiss='modal'*/}
            {/*  >*/}
            {/*    Close*/}
            {/*  </button>*/}
            {/*  <button type='button' className='btn btn-primary'>*/}
            {/*    Save changes*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
      {/* Option 1: Bootstrap Bundle with Popper */}
      {/* Custom JS */}
    </>
  );
};

export default Aman;
