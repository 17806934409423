import React, { useEffect, useState } from "react";
/*import axios from "axios";*/
import $ from "jquery";
import ImageCard from "./component/cards/ImageCard";

const System_menu = ({
  companySystems,
  userPages,
  groupPages,
  systemAdmintrator,
  superUser,
  menus,
  page_name,
  setUserControl,
  setFunctionCode,
}) => {
  function toggle_node(
    function_cd,
    system_cd,
    icon_name,
    node_id,
    page_name,
    function_name,
  ) {
    //document.getElementById("function_code").innerText = function_cd

    const sysyemcd = companySystems.find(
      (system) => system.system_cd === system_cd,
    );
    const userpage = userPages.find((page) => page.function_cd === function_cd);
    const grouppage = groupPages.find(
      (grouppage) => grouppage.function_cd === function_cd,
    );
    const systemadmintrator = systemAdmintrator.find(
      (systemadmin) => systemadmin.system_cd === system_cd,
    );

    /* console.log("group pages result is ", grouppage)*/
    document.getElementById("page_name_area").innerText = "";
    setUserControl("");
    setFunctionCode("");
    if (function_name) {
      //if (userpage || grouppage || systemadmintrator || superUser) {
      document.getElementById("page_name_area").innerText = page_name;
      if (window.matchMedia("(max-width: 767.98px)").matches) {
        if (document.getElementById(node_id).innerHTML === "") {
          $("html").toggleClass("minify");
          var element = document.getElementById("menusidebar");
          element.classList.add("deactive");
        }
      }
      setUserControl(function_name);
      setFunctionCode(function_cd);

      //} else {
      //    alert("sorry you dont have prevelage for this function")
      //}
    } else {
      if (sysyemcd) {
        if (document.getElementById(node_id).style.display === "block") {
          document.getElementById(node_id).style.display = "none";
        } else {
          document.getElementById(node_id).style.display = "block";
        }
        console.log("node_id", document.getElementById(node_id).innerHTML);
        console.log("node_id", node_id);

        if (window.matchMedia("(max-width: 767.98px)").matches) {
          // The screen width is less than or equal to 767.98px
          if (document.getElementById(node_id).innerHTML === "") {
            $("html").toggleClass("minify");
            var element = document.getElementById("menusidebar");
            element.classList.add("deactive");
          }
        }
      } else {
        alert("sorry you dont have prevelage for this pahage");
      }
    }
  }

  const subNode = (parent_id) => {
    return menus.map((tree_node, i) => {
      if (parent_id === tree_node.parent_id) {
        return (
          <div key={i}>
            <div key={i} className="user_line">
              <div className="node_line">
                <div
                  style={{ display: "flex" }}
                  onClick={() => {
                    toggle_node(
                      tree_node.function_cd,
                      tree_node.system_cd,
                      tree_node.icon_name,
                      tree_node.node_id,
                      tree_node.page_name,
                      tree_node.function_name,
                    );
                  }}
                >
                  <div className="icon_tree_area">
                    <ImageCard
                      icon={tree_node.icon_name}
                      iconType={"system menu tree"}
                      className="icon-togle"
                    />
                  </div>
                  <div className="node_name_area">{tree_node.page_name}</div>
                </div>
              </div>
            </div>
            <div id={tree_node.node_id} className="node_block_section hedden">
              {subNode(tree_node.node_id)}
            </div>
          </div>
        );
      }
    });
  };

  return (
    <>
      {menus.map((tree_node, i) => {
        if (tree_node.parent_id === "0")
          return (
            <>
              <div key={i} className="user_line">
                <div className="node_line">
                  <div
                    style={{ display: "flex" }}
                    onClick={() => {
                      toggle_node(
                        tree_node.function_cd,
                        tree_node.system_cd,
                        tree_node.icon_name,
                        tree_node.node_id,
                        tree_node.function_name,
                      );
                    }}
                  >
                    <div className="icon_tree_area">
                      <ImageCard
                        icon={tree_node.icon_name}
                        iconType={"system menu tree"}
                      />
                    </div>
                    <div className="node_name_area">{tree_node.page_name}</div>
                  </div>
                </div>
              </div>
              <div id={tree_node.node_id} className="node_block_section hedden">
                {subNode(tree_node.node_id)}
              </div>
            </>
          );
      })}
    </>
  );
};

export default System_menu;
