

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Clients from "../Crm/Clients";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { TiAttachment } from "react-icons/ti"
import { BsTrash3 } from "react-icons/bs"
/*import { LuEdit2 } from "react-icons/lu"*/

import { useDispatch, useSelector } from "react-redux"; //this import redux functions
import { Link } from "react-router-dom"
import pdf from '../../Images/icons/pdf.png'
import cam from '../../Images/icons/cam.png'



import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageCard from "../cards/ImageCard";

const LccClients = () => {

    var isName = ""
    const dispatch = useDispatch({})
    const [clientMode, setClientMode] = useState("Initial"); 
    const [clientDetails, setClientDetails] = useState({}); 
    const [pdfFileBase64, setPdfFileBase64] = useState("");
    const [loadingObject, setLoadingObject] = useState([])
    const [clientsList, setClientsList] = useState([])
    const [filesNames, setFileNames] = useState([])
    const [clientCode, setClientCode] = useState(0);
    const [systemCd, setSystemCd] = useState("Lcc");

    const filePickerRef = useRef(null)
    const [uploadprecentage, setUploadPercentage] = useState(0)
    const [showCrop, setShowCrop] = useState(false);
    const profileimgRef = useRef(null)

    const { filePercentageReducers } = useSelector((state) => state)

    useEffect(() => {
       
    }, []);

    useEffect(() => {
        if (clientCode > 0) { getClientAttachments() }
    }, [clientCode]);

    // upload ----------------------
    /*this function to get input files to be uploded*/
    const filePickedHandler = (event) => {
        let pickedFile;
        if (event.target.files) {

            for (var i = 0; i < event.target.files.length; i++) {
                let fileExttype = event.target.files[i].type.split("/")[i];
                let filExt = event.target.files[i].type.split("/")[1];
   
                    pickedFile = event.target.files[i];
                    
                    const reader = new FileReader();
                    reader.readAsDataURL(event.target.files[i])
                    reader.addEventListener('load', () => {
                        setPdfFileBase64(reader.result)
                    })
                    /*  handle the input files for list*/
                    console.log("files to uploaded = ", pickedFile)
                    uploadFile(pickedFile,i);
                
            }
        }
    };

    const uploadFile = (pickedFile) => {

        console.log(pickedFile.name)

        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        let formData = new FormData();
        formData.append("file", pickedFile);
        formData.append("client_cd", 1)
        formData.append("system_cd", "lcc")
        formData.append("company", userDataLocal.company_cd)
        formData.append("flocation", "Lcc");

        dispatch({
            type: "FILEUPLOADS",
            payload: { [pickedFile.name]: 0},
        });

        const options = {
            headers: { Authorization: `Bearer ${userDataLocal.token}`, },

            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                if (percent <= 100) {
                    setUploadPercentage(percent);
                    dispatch({
                        type: "FILEUPLOADS",
                        payload: { [pickedFile.name]: percent},
                    });
                }
            },
        };

        axios
            .post(`${process.env.REACT_APP_BACK_END_URL}/api/crm/update_client_attachment`, formData, options)
            .then((res) => {

                console.log(res.data)
                if (res.data.result.rstatus === "1") {
                
                    dispatch({
                        type: "FILEUPLOADS",
                        payload: { [pickedFile.name]: 0 },
                    });

                    setFileNames((old) => [...old, { attachment_name: res.data.attachment_name, attactment_type: res.data.attachtment_type, fileurl: res.data.fileurl }])

                }
                setUploadPercentage(0);

            })
            .catch((err) => {
                dispatch({
                    type: "FILEUPLOADS",
                    payload: { [pickedFile.name]: 0 },
                });
                console.log(err);
                if (err.response) {
                    if (err.response.status === 401) {
                        localStorage.removeItem("userDataSessionWay");
                        window.location.replace("/");
                    }
                }

                setUploadPercentage(0);
            });
    }

    const closeProfileHandler = () => setShowCrop(false);

    const getClientAttachments = async () => {
        setClientsList([])
        setFileNames([])
        try {
            let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

            const data = {
                info: {
                    lang: userDataLocal.lang,
                    company: userDataLocal.company_cd,
                    user_cd: userDataLocal.user_cd,
                },
                input: {
                    actioncode: "get_client_attachments",
                    system_cd: "Lcc",
                    client_cd: clientCode                   
                },
            };

            console.log("getClientAttachments input ", data)
            
            let res = await axios.post(
                `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client_attachments`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${userDataLocal.token}`,
                    }
                }
            );

            console.log("getClientAttachments output", res);
            if (res.data.output.client_attachments) { setClientsList(res.data.output.client_attachments)}
           
        } catch (err) {
            console.log(err);
            alert("error message" + err.message);
        }
    };
   
    return <>
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5  border-right">
                <div style={{ height: "calc(100vh - 86px)", overflow: "auto", overflowX: "hidden" }} >
                    <Clients setClientCode={setClientCode} systemCd={systemCd } />
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5  border-right">
                <div style={{ height: "calc(100vh - 86px)", overflow: "auto", overflowX: "hidden" }} >
                    {clientCode > 0 &&
                        <>                        
                            {/*Buttons bar*/}           
                            <div>
                                <div className="normal_float normal_text_align "></div>
                                <div className="opposite_float opposite_text_align">
                                    <Tooltip title="Search" placement="bottom" arrow>
                                        <IconButton>
                                            <TiAttachment color="inherit" size="26" onClick={() => filePickerRef.current.click()} />
                                        </IconButton>
                                    </Tooltip>
                                    <input ref={filePickerRef} style={{ display: "none" }} type="file" multiple onChange={filePickedHandler} accept="*" />
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                            {/*Attachment area*/}
                            {clientsList.length > 0 && clientsList.map((clients_list, index) => (
                                <Link  key={clients_list.client_cd + index} to={{ pathname: clients_list.client_url_address }} target="_blank" className="file-wrapper">
                                    <div className="normal_float" style={{ display: "flex", height: "30px" }}>
                                        {clients_list.attactment_type === "pdf" &&
                                            (<>
                                                <div style={{ padding: "4px" }}><img src={pdf} alt="" style={{height:"22px"} } /></div>
                                            </>)}
                                        {clients_list.attactment_type === "image" &&
                                            (<>
                                                <div style={{ padding: "4px" }}><img src={cam} alt="" style={{ height: "22px" }} /></div>
                                        </>)}
                                        <div style={{ padding: "4px" }}><img src={"https://www.amanrow.com/assets/global/img/flags/" + clients_list.attactment_type + ".png"} alt=" " className="flag_icon" /></div>
                                        <div style={{ padding: "4px" }}>{clients_list.attachment_name}</div>  
                                    </div>
                                    <div className="opposite_float row1" >                             
                                            {/*<LuEdit2 size="16" style={{ margin: "0px 3px" }} />*/}
                                        <BsTrash3 size="16" style={{ margin: "0px 3px" }} />
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                </Link>   
                            )
                        )}
                            {/*new uploaded Attachments*/}
                        {filesNames.map((newattchlist) => {
                            return <>
                                <Link key={newattchlist.attachment_name} to={{ pathname: newattchlist.fileurl }} target="_blank" className="file-wrapper">
                                    <div className="normal_float" style={{ display: "flex", height: "30px" }}>
                                     
                                        <ImageCard type={newattchlist.attactment_type} />
                              
                                        <div style={{ padding: "4px" }}>{newattchlist.attactment_type}</div>
                                        <div style={{ padding: "4px" }}>{newattchlist.attachment_name}</div>  
                                    </div>
                                    
                                    <div className="opposite_float row1" >
                                      {/*  <LuEdit2 size="16" style={{ margin: "0px 3px" }} />*/}
                                        <BsTrash3 size="16" style={{ margin: "0px 3px" }} />
                                    </div>
                                    <div style={{clear:"both"} }></div>
                                </Link>
                            </>
                        })}

                   
                            {
                                Object.entries(filePercentageReducers).map((pdfFile, i) => {
                                    if (pdfFile[1] > 0) {
                                        return (
                                            <div className="progress-wrapper">
                                                <div>{pdfFile[0]}</div>
                     
                                                    <div className="progress">
                                                            <div
                                                                className="progress-bar progress-bg-color rogress-bar-striped"
                                                                role="progressbar"
                                                                style={{ width: `${pdfFile[1]}%`, height: "100%" }}
                                                                aria-valuenow="100"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                            </div>
                                                        </div>

                                               

                                                <div className="col-auto">
                                                    <span
                                                        className="progress-color cursor-pointer"
                                                    >
                                                        {pdfFile[1]}%
                                                    </span>
                                                </div>

                                            </div>
                                        )


                                    } else {
                                        return ""
                                    }


                                })}
                        </>}
                </div>
        
            </div>
        </div>
    </>
}

export default LccClients