

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { LuUserPlus } from "react-icons/lu";
import { BsTrash3 } from "react-icons/bs";
/*import { LuEdit2 } from "react-icons/lu"*/
import { TbCheckbox } from "react-icons/tb";
import { RiCheckboxBlankLine } from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux"; //this import redux functions
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageCard from "../cards/ImageCard";

const CaseClient = ({setShowSideBar, case_cd, caseClients, setCaseClients }) => {
  const [lccClients, setLccClients] = useState([]);
  const [areasMode, setAreasMode] = useState("List");
  const [viewMode, setViewMode] = useState("");
  const [clientPageLables, setClientPageLables] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [clientAttachments, setClientAttachments] = useState([]);
  const [powerOfAttornyAttachments, setPowerOfAttornyAttachments] = useState(
    [],
  );
  
    useEffect(() => {
        get_case_clients();
    }, []);

    const set_clear_mode = () => {
        setClientDetails({});
        switch (areasMode) {
            case "Edit":
                setAreasMode("List");
                break;
            case "View":
                setAreasMode("Edit");
                break;
            case "List":
                setShowSideBar(false);
                break;
            default:
        }
    };

    const get_case_clients = async () => {
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "get_case_clients_and_lccclients",
            case_cd: case_cd,
        },
        };
        console.log("get_case_clients input ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_case_clients_and_lccclients`,

        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );

        console.log("get_case_clients output ", res);
        if (res.data.output.page_lables) {
            setClientPageLables(res.data.output.page_lables);
        }
        if (res.data.output.lcc_clients) {
        setLccClients(res.data.output.lcc_clients);
        }
        if (caseClients.length === 0) {
        setAreasMode("list");
        }
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    function get_client(client_cd, view_mode) {
        get_client_detail(client_cd, view_mode);
        getClientAttachments(client_cd);
    }

    const get_client_detail = async (client_cd, view_mode) => {
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "get_client",
            client_cd: client_cd,
            country_area_cd: 965,
            phone_no: "",
        },
        };
        console.log("getClient input ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/crm/get_client`,

        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );

        console.log("getClient output ", res);

        if (res.data.output.client) {
        setClientDetails(res.data.output.client);
        setViewMode(view_mode);
        setAreasMode("View");
        } else {
        alert("no record found");
        }
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    const getClientAttachments = async (client_cd) => {
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));

        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "get_client_and_poa_attatcments",
            client_cd: client_cd,
        },
        };

        console.log("get_client_and_poa_attatcments input ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/get_client_and_poa_attatcments`,
        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );

        console.log("get_client_and_poa_attatcments output", res);
        if (res.data.output.clients_attachments) {
        setClientAttachments(res.data.output.clients_attachments);
        }
        if (res.data.output.attorneies_attachments) {
        setPowerOfAttornyAttachments(res.data.output.attorneies_attachments);
        }
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    /* update -------------------------*/
    const update_client = async (client_cd, update_function) => {
    try {
        let userDataLocal = JSON.parse(localStorage.getItem("amanrowuserData"));
        const data = {
        info: {
            lang: userDataLocal.lang,
            company: userDataLocal.company_cd,
            user_cd: userDataLocal.user_cd,
        },
        input: {
            actioncode: "update_case_client",
            case_cd: case_cd,
            client_cd: client_cd,
            update_function: update_function,
        },
        };
        console.log("update_case_client input ", data);

        let res = await axios.post(
        `${process.env.REACT_APP_BACK_END_URL}/api/lcc/update_case_client`,

        data,
        {
            headers: {
            Authorization: `Bearer ${userDataLocal.token}`,
            },
        },
        );

        console.log("update_case_client output ", res);
        setCaseClients([]);
        if (res.data.output.case_clients) {
        setCaseClients(res.data.output.case_clients);
        }

        //if (update_function === "delete") {
        //    document.getElementById(client_cd).style.display = "block"
        //    alert("will show " + document.getElementById(client_cd).style.display)
        //} else {
        //    alert("will hide " + document.getElementById(client_cd).style.display)
        //    document.getElementById(client_cd).style.display = "none"
        //}
        //alert("the display is  " + document.getElementById(client_cd).style.display)
        setClientDetails({});
        setAreasMode("Edit");
    } catch (err) {
        console.log(err);
        alert("error message" + err.message);
    }
    };

    return (
    <>     
        {/*buttomn bar*/}
        <div>
            <div className="normal_float" style={{display:"flex", textAlign:"center", height: "46px" }}>
                <Tooltip title="Back" placement="bottom" arrow>
                    <IconButton onClick={() => {set_clear_mode()}}>
                        <ArrowBackIcon color="inherit" fontSize="small" />
                    </IconButton>
                </Tooltip>                
            </div>
            <div className="opposite_float"  style={{display:"flex", textAlign:"center", height: "46px" }}>
                {areasMode === "List" && (
                <>
                    <Tooltip title="Edit" placement="bottom" arrow>
                    <IconButton
                        onClick={() => {
                        setAreasMode("Edit");
                        }}
                    >
                        <ModeOutlinedIcon color="inherit" fontSize="small" />
                    </IconButton>
                    </Tooltip>
                </>
                )}
                {areasMode === "View" && (
                <>
                    {viewMode === "insert" && (
                    <>
                        <Tooltip title="add New" placement="bottom" arrow>
                        <IconButton
                            onClick={() => {
                            update_client(clientDetails.client_cd, "insert");
                            }}
                        >
                            <LuUserPlus color="inherit" size={18} />
                        </IconButton>
                        </Tooltip>
                    </>
                    )}
                    {viewMode === "delete" && (
                        <Tooltip title="add New" placement="bottom" arrow>
                            <IconButton onClick={() => {update_client(clientDetails.client_cd, "delete")}}>
                                <BsTrash3 color="inherit" size={18} />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
                )}
            </div>
            <div style={{ clear: "both" }}></div>
        </div>

        {/*case clients list area*/}
        
        {(areasMode === "List" || areasMode === "Edit") && (
        <>
            <div className="table_header_div top_border_div table_header_background"style={{ display: "flex" }}>
                <div style={{ width: "7%" }}></div>
                <div style={{ width: "93%" }}>Client name</div>
            </div>
            {caseClients.length > 0 && caseClients.map((clients_list, index) => (
                <div className="row_area" key={clients_list.client_cd + index}>
                    <div className="table_line_div bottom_border_div">
                        <div style={{ width: "7%" }}>{areasMode === "Edit" && <TbCheckbox size="20" />}</div>
                        <div style={{ width: "93%" }}>{clients_list.client_name}</div>                       
                    </div> 
                
                
                </div>
            ))}

            <div>-----------------------</div>
            {areasMode === "Edit" && (
            <>
                {console.log("caseClients = ", caseClients)}
                {caseClients.length === 0 && lccClients.map((lcc_clients, index) => (
                    <div className="row_area" key={lcc_clients.client_cd + index} >
                        <div>
                            <div className="table_line_div normal_float" onClick={() => {get_client(lcc_clients.client_cd, "insert")}}>
                                <div style={{ width: "24px" }}>{areasMode === "Edit" && (<RiCheckboxBlankLine size="20" />)}</div>
                                <div>{lcc_clients.client_name}</div>
                            </div>
                            <div className="opposite_float flip_display_area">
                                {areasMode === "Edit" && (<LuUserPlus size="18" style={{ margin: "0px 3px" }} onClick={() => {update_client( lcc_clients.client_cd, "insert")}}/>)}{" "}
                            </div>
                            <div style={{ clear: "both" }}></div>
                        </div>
                    </div>
                ))}

                <div>-----------------------</div>

                {(caseClients.length === 0 ||(lccClients.length > 0 && caseClients.length > 0)) && lccClients.map((lcc_clients, index) => {
                    return caseClients.map((el) => {
                    if (el.client_cd !== lcc_clients.client_cd)
                        return (
                        <div
                            className="row_area"
                            key={lcc_clients.client_cd + index}
                        >
                            <div>
                            <div
                                className="table_line_div normal_float"
                                onClick={() => {
                                get_client(lcc_clients.client_cd, "insert");
                                }}
                            >
                                <div style={{ width: "24px" }}>
                                {areasMode === "Edit" && (
                                    <RiCheckboxBlankLine size="20" />
                                )}
                                </div>
                                <div>{lcc_clients.client_name}</div>
                            </div>
                            <div className="opposite_float flip_display_area">
                                {areasMode === "Edit" && (
                                <LuUserPlus
                                    size="18"
                                    style={{ margin: "0px 3px" }}
                                    onClick={() => {
                                    update_client(
                                        lcc_clients.client_cd,
                                        "insert",
                                    );
                                    }}
                                />
                                )}{" "}
                            </div>
                            <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                        );
                    });
                })}
            </>
            )}
        </>
        )}

        {areasMode === "View" && (
        <>
            {/*Client details*/}

            <div style={{ display: "flex" }}>
                <label className="label_area"> {clientPageLables[0].description} </label>
                <div class="viewing_field">{clientDetails && clientDetails.client_cd}</div>
            </div>
            <div style={{ display: "flex" }}>
                <label className="label_area"> {clientPageLables[1].description} </label>
                <div class="viewing_field">{clientDetails && clientDetails.client_name}</div>
            </div>
            {clientDetails && clientDetails.client_type_cd !== "1" && (
            <>
                <div style={{ display: "flex" }}>
                <label className="label_area">
                    {clientPageLables[13].description}
                </label>
                <div class="viewing_field">
                    {clientDetails && clientDetails.client_type}
                </div>
                </div>
                <div style={{ display: "flex" }}>
                <label className="label_area">
                    {clientPageLables[14].description}
                </label>
                <div class="viewing_field">
                    {clientDetails && clientDetails.contact_name}
                </div>
                </div>
            </>
            )}
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[2].description}
            </label>
            <div class="viewing_field">
                {clientDetails && clientDetails.client_id}
            </div>
            </div>
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[3].description}
            </label>
            <div class="viewing_field">
                {clientDetails && clientDetails.sex}
            </div>
            </div>
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[4].description}
            </label>
            <div class="viewing_field">
                {clientDetails && clientDetails.nationality}
            </div>
            </div>
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[7].description}
            </label>
            <div class="viewing_field">
                {clientDetails && clientDetails.email_address}
            </div>
            </div>
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[6].description}
            </label>
            <div class="viewing_field">
                <img
                src={
                    "https://www.amanrow.com/assets/global/img/flags/" +
                    clientDetails.phone_country_flag_id +
                    ".png"
                }
                alt=" "
                className="flag_icon"
                />
                {clientDetails &&
                "  (" +
                    clientDetails.country_area_cd +
                    ")" +
                    "  " +
                    clientDetails.phone_no}
            </div>
            </div>
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[5].description}
            </label>
            <div class="viewing_field">
                <img
                src={
                    "https://www.amanrow.com/assets/global/img/flags/" +
                    clientDetails.country_id +
                    ".png"
                }
                alt=" "
                className="flag_icon"
                />
                {clientDetails && " " + clientDetails.country_name}
            </div>
            </div>
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[8].description}
            </label>
            <div class="viewing_field">
                {clientDetails && clientDetails.state_name}
            </div>
            </div>
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[9].description}
            </label>
            <div class="viewing_field">
                {clientDetails && clientDetails.city_name}
            </div>
            </div>
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[10].description}
            </label>
            <div class="viewing_field">
                {clientDetails && clientDetails.postal_cd}
            </div>
            </div>
            <div style={{ display: "flex" }}>
            <label className="label_area">
                {clientPageLables[11].description}
            </label>
            <div class="viewing_field">
                {clientDetails && clientDetails.client_address}
            </div>
            </div>

            {/*clients Attachment*/}
            <div style={{ height: "10px" }}></div>
            {clientAttachments.length === 0 && (
            <div>no attachment found for clients</div>
            )}
            {clientAttachments.length > 0 &&
            clientAttachments.map((client_attachments_list, index) => (
                <Link
                key={client_attachments_list.client_url_address + index}
                to={{
                    pathname: client_attachments_list.client_url_address,
                }}
                target="_blank"
                className="file-wrapper"
                >
                <div style={{ display: "flex", height: "30px" }}>
                    <div className="icon_tree_area">
                    <ImageCard
                        type={client_attachments_list.attactment_type}
                    />
                    </div>

                    <div style={{ padding: "4px" }}>
                    {client_attachments_list.attachment_name}
                    </div>
                </div>
                </Link>
            ))}

            {/*powe of attorny Attachment*/}
            {powerOfAttornyAttachments.length === 0 && (
            <div>no attachent found for powr of attorny</div>
            )}
            {powerOfAttornyAttachments.length > 0 &&
            powerOfAttornyAttachments.map((poa_attachments_list, index) => (
                <Link
                key={poa_attachments_list.power_of_attorney_cd + index}
                to={{
                    pathname: poa_attachments_list.attachment_url_address,
                }}
                target="_blank"
                className="file-wrapper"
                >
                <div style={{ display: "flex", height: "30px" }}>
                    <div className="icon_tree_area">
                    <ImageCard
                        type={poa_attachments_list.attactment_type}
                    />
                    </div>
                    <div style={{ padding: "4px" }}>
                    {poa_attachments_list.title}
                    </div>
                </div>
                </Link>
            ))}
        </>
        )}
        
    </>
    );
};

export default CaseClient;
