import React from "react";
import ReactDOM from "react-dom";
import "./style/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//import 'bootstrap/dist/css/bootstrap.min.css';
import "./style/defualtStyle.css";

//reducers
import loginCredentails from "./reducers/loginCredentails";
import userData from "./reducers/userData";
import FilePercentageReducers from "./reducers/UploadingFiles"

import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";

import ThemeLanguageSelector from "./component/ThemeSelecterLanguage/ThemeLanguageSelector";

const store = createStore(
  combineReducers({
    Login: loginCredentails,
      user: userData,
      filePercentageReducers: FilePercentageReducers
  }),
  composeWithDevTools(),
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeLanguageSelector>
        <App />
      </ThemeLanguageSelector>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
